import React, { useState } from "react";

import ImageAndText from "components/ImageAndText";
import LoadMoreButton from "components/LoadMoreButton";

import * as styles from "./styles.module.scss";

type NewsListType = {
  title: string;
  items: NewsItem[];
};

export type NewsItem = {
  node: {
    excerpt: string;
    id: string;
    title: string;
    url: string;
    news_meta: {
      window_title: string;
      meta_description: string;
      time_to_read: string;
      publication_date: string;
    };
    images: {
      featured_image: {
        url: string;
        title: string;
      };
      image_alt_text: string;
    };
  };
};

const NewsList: React.FC<NewsListType> = ({ items, title }) => {
  const [numberOfItems, setNumberOfItems] = useState(4);
  return (
    <section className={styles.newsList}>
      <div className="container">
        <h2>{title}</h2>
        <ul>
          {items.slice(0, numberOfItems).map((item: NewsItem) => {
            return (
              <li key={item.node.id}>
                <ImageAndText
                  copy={item.node.excerpt}
                  copyMaxLength={320}
                  imageAltText={item.node.images?.image_alt_text}
                  imageTitle={item.node.images?.featured_image?.title}
                  image={item.node.images?.featured_image?.url}
                  internalLinkSlug={item.node.url}
                  publicationDate={item.node.news_meta.publication_date}
                  readingTime={true}
                  title={item.node.title}
                  titleMaxLength={80}
                />
              </li>
            );
          })}
        </ul>
        {items.length > numberOfItems ? (
          <LoadMoreButton handleClick={() => setNumberOfItems(numberOfItems + 4)} label="Show more" />
        ) : null}
      </div>
    </section>
  );
};

export default NewsList;
