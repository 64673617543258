import React from "react";
import { graphql } from "gatsby";

import Meta from "components/Meta";
import Layout from "components/Layout";
import NewsList, { NewsItem } from "components/NewsList";
import HeroCarousel from "components/Hero/Carousel";
import { HeroCarouselProps } from "components/Hero/Carousel";
import { useNewsletterPopup } from "components/Newsletter/hooks/newsletterPopup";
import { DefaultMetaType, MetaType } from "modules/meta/types";
import { NewsletterPopupType } from "modules/newsletter/types";

type QueryTypes = {
  contentstackNewsletterPopup: NewsletterPopupType;
  allContentstackNewsPage: {
    edges: NewsItem[];
    meta: {
      window_title: string;
      meta_description: string;
    };
    title: string;
  };
  contentstackGlobalMeta: DefaultMetaType;
  contentstackNewsListPage: {
    meta: MetaType;
    newsletter: {
      show_popup_page: string;
    };
    hero_carousel: HeroCarouselProps;
  };
};

type NewsProps = { data: QueryTypes; location: any };

const News: React.FC<NewsProps> = ({ data, location }) => {
  const newsletter = data?.contentstackNewsletterPopup;
  const page = data?.contentstackNewsListPage;

  useNewsletterPopup({
    config: newsletter?.config,
    copy: newsletter?.copy,
    submitted: newsletter?.submitted,
    pageOverride: page?.newsletter?.show_popup_page,
  });

  return (
    <Layout>
      <Meta defaultMeta={data?.contentstackGlobalMeta} location={location} meta={data?.contentstackNewsListPage.meta} />
      {data?.contentstackNewsListPage.hero_carousel && (
        <HeroCarousel {...data?.contentstackNewsListPage.hero_carousel} />
      )}
      <NewsList items={data?.allContentstackNewsPage.edges} title="News" />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    contentstackNewsletterPopup {
      ...newsletterPopup
    }
    contentstackGlobalMeta {
      ...defaultMeta
    }
    allContentstackNewsPage(sort: { order: DESC, fields: news_meta___publication_date }) {
      edges {
        node {
          excerpt
          id
          news_meta {
            window_title
            publication_date
            meta_description
          }
          images {
            featured_image {
              url
              title
            }
            image_alt_text
          }
          title
          url
        }
      }
    }
    contentstackNewsListPage(id: { eq: $id }) {
      ...metaNewsList
      newsletter {
        show_popup_page
      }
      ...newsHeroCarousel
    }
  }
`;

export default News;
