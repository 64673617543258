import React from "react";
import clsx from "clsx";

import * as Button from "components/Button";
import LoadingSVG from "modules/theme/icons/loading/button-loading.svg";

import * as styles from "./styles.module.scss";

type LoadMoreButtonProps = {
  handleClick: () => void;
  label?: string;
  isLoading?: boolean;
  noContainer?: boolean;
};

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
  handleClick,
  label = "Load more",
  isLoading,
  noContainer,
}) => {
  const className = clsx(styles.loadMoreButton, isLoading && styles.loading);
  return noContainer ? (
    <Button.Generic className={className} disabled={isLoading} label={label} onClick={handleClick}>
      {isLoading && (
        <div>
          <LoadingSVG />
        </div>
      )}
    </Button.Generic>
  ) : (
    <div className="container small">
      <Button.Generic className={className} disabled={isLoading} label={label} onClick={handleClick}>
        {isLoading && (
          <div>
            <LoadingSVG />
          </div>
        )}
      </Button.Generic>
    </div>
  );
};

export default LoadMoreButton;
