import React from "react";
import { Link } from "gatsby";
import { stripTags, truncate } from "modules/utils";
import LazyloadImage from "modules/lazyload/Image";

import * as styles from "./styles.module.scss";

type ImageAndTextProps = {
  copy: string;
  copyMaxLength?: number;
  image: string;
  imageAltText: string;
  imageTitle: string;
  internalLinkSlug: string;
  createdAt?: string;
  readingTime: boolean;
  title: string;
  titleMaxLength?: number;
  publicationDate: string;
};

const ImageAndText: React.FC<ImageAndTextProps> = ({
  copy,
  copyMaxLength,
  image,
  imageAltText,
  imageTitle,
  internalLinkSlug,
  title,
  titleMaxLength,
  publicationDate,
}) => {
  const date_format = new Date(publicationDate);
  const published = date_format.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" }); // Month (name) day, year

  const alt = imageAltText ? imageAltText : imageTitle;
  const truncTitle = titleMaxLength ? truncate(title, titleMaxLength) : truncate(title, 80);
  return (
    <article className={styles.imageAndText}>
      <div>
        <LazyloadImage
          alt={alt}
          desktop={{ height: 400, width: 720, aspectRatio: "9:5" }}
          mobile={{ height: 175, width: 328, aspectRatio: "328:175" }}
          src={image}
          tagName="img"
        />
        <span></span>
      </div>
      <div>
        {published && <div>{published}</div>}
        <h3>
          {internalLinkSlug ? (
            <Link aria-label={title} className="link" to={internalLinkSlug}>
              {truncTitle}
            </Link>
          ) : (
            truncTitle
          )}
        </h3>
        <div>
          <p>{copyMaxLength ? truncate(stripTags(copy), copyMaxLength) : truncate(stripTags(copy), 100)}</p>
        </div>
      </div>
    </article>
  );
};

export default ImageAndText;
